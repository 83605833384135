<template>
  <div class="common-layout">
    <slot />
  </div>
</template>
<style lang="scss">
.qrcode-img {
  z-index: 9999; 
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to
{
  opacity: 0;
}
body,html{
  padding: 0px;
  margin: 0px;
}
a{
    text-decoration: none;
    width: 100px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(74, 74, 74, 1);
}

.header-body{
  padding: 0px;
  margin: 0px;
  width: 100%;
}
.header-body .top{
  width: 100%;
  height: 40px;
  background-color: #f5f5f5;
}
.header-body.not {
  border-bottom: 1px solid #f5f5f5;
}
.header-body .not{
  display: flex;
  width: 100%;
}
.header-body .top .main{
  margin: 0 auto;
  width: 1200px;
  height: 40px;
  display: flex;
}
.not .main{
  margin: 0 auto;
  width: 1200px;
  display: flex;
  margin-top: 10px;
}
.not .main .left{
  .logo{
    width: 180px;
    height: 55px;
    border: 0;
    vertical-align: top;
    cursor: pointer;
    object-fit: cover;
  }
}
.not .main .right .title{
    width: 100px;
    line-height: 12px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: #333;
    cursor: pointer;
    margin-top: 31px;
    z-index: 9999; 
}
.left .title{
    width: 100px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(74, 74, 74, 1);
}
.left .title a{
  cursor: pointer;
}
.main .right{
  flex: 1;
}
.right .title{
    width: 100px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(74, 74, 74, 1);
}
.right .title a{
  position:absolute;
  z-index: 9999; 
  cursor: pointer;
}
</style>